var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"d-flex px-5 py-4",attrs:{"cols":12}},[_c('v-spacer'),_c('v-btn',{staticClass:"pr-4",attrs:{"color":"secondary","dark":"","depressed":"","disabled":_vm.sending},on:{"click":function($event){return _vm.doBack()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" Cancelar ")],1),(_vm.hasPermissions)?_c('MenuPreview',{attrs:{"permissions":_vm.permissions || []},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.resetPassword)?_c('v-btn',_vm._g(_vm._b({staticClass:"px-4 ml-2",attrs:{"color":"secondary","dark":"","depressed":"","disabled":_vm.sending}},'v-btn',attrs,false),on),[_vm._v(" Preview Menu ")]):_vm._e()]}}],null,false,858947036)}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"390"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.resetPassword)?_c('v-btn',_vm._g(_vm._b({staticClass:"px-4 ml-2",attrs:{"color":"secondary","dark":"","depressed":"","disabled":_vm.sending}},'v-btn',attrs,false),on),[_vm._v(" Redefinir Senha ")]):_vm._e()]}}]),model:{value:(_vm.passwordDialog),callback:function ($$v) {_vm.passwordDialog=$$v},expression:"passwordDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-1",staticStyle:{"display":"block"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"x-large":""}},[_vm._v(" mdi-lock-reset ")]),_vm._v(" Redefinir Senha "),_c('v-btn',{staticStyle:{"position":"absolute","right":"5px","top":"5px"},attrs:{"icon":""},on:{"click":function($event){_vm.passwordDialog = false}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[_c('p',[_vm._v(" Deseja redefinir a senha do usuário "),_c('b',[_vm._v(_vm._s(_vm.userName))]),_vm._v("? ")]),_c('p',[_vm._v(" O usuário perderá o acesso a plataforma Teros até que ele conclua o processo de redefinição de senha. ")]),_c('p',[_vm._v(" O usuário receberá em seu e-mail "),_c('b',[_vm._v(_vm._s(_vm.userEmail))]),_vm._v(" o link para que conclua o processo. ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","dark":"","depressed":""},on:{"click":function($event){_vm.passwordDialog = false}}},[_vm._v(" Não ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.resetSenha()}}},[_vm._v(" Sim ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"390"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.deleteUser)?_c('v-btn',_vm._g(_vm._b({staticClass:"px-4 ml-2",attrs:{"color":"accent","dark":"","depressed":"","disabled":_vm.sending}},'v-btn',attrs,false),on),[_vm._v(" Excluir ")]):_vm._e()]}}]),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-1",staticStyle:{"display":"block"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red","x-large":""}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" Exclusão de Usuário "),_c('v-btn',{staticStyle:{"position":"absolute","right":"5px","top":"5px"},attrs:{"icon":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[_c('p',[_vm._v("Deseja realmente excluir o usuário "+_vm._s(_vm.userName)+"?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","dark":"","depressed":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Não ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.doDelete()}}},[_vm._v(" Sim ")])],1)],1)],1),_c('v-btn',{staticClass:"px-4 ml-2",attrs:{"color":"primary","dark":"","depressed":"","disabled":_vm.sending},on:{"click":function($event){return _vm.$emit('save', $event)}}},[_vm._v(" Salvar ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }