<template>
  <v-col class="d-flex px-5 py-4" :cols="12">
    <v-spacer></v-spacer>

    <v-btn class="pr-4" color="secondary" dark depressed :disabled="sending" @click="doBack()">
      <v-icon left>mdi-chevron-left</v-icon> Cancelar
    </v-btn>
    
    <MenuPreview v-if="hasPermissions" :permissions="permissions || []">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-if="resetPassword"
          v-on="on"
          class="px-4 ml-2"
          color="secondary"
          dark
          depressed
          :disabled="sending"
        >
          Preview Menu
        </v-btn>
      </template>
    </MenuPreview>

    <v-dialog v-model="passwordDialog" persistent max-width="390">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-if="resetPassword"
          v-on="on"
          class="px-4 ml-2"
          color="secondary"
          dark
          depressed
          :disabled="sending"
        >
          Redefinir Senha
        </v-btn>
      </template>

      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3"> mdi-lock-reset </v-icon> Redefinir Senha
          <v-btn
            icon
            style="position: absolute; right: 5px; top: 5px"
            @click="passwordDialog = false"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-2">
          <p>
            Deseja redefinir a senha do usuário <b>{{ userName }}</b>?
          </p>
          <p>
            O usuário perderá o acesso a plataforma Teros até que ele conclua o
            processo de redefinição de senha.
          </p>
          <p>
            O usuário receberá em seu e-mail <b>{{ userEmail }}</b> o link
            para que conclua o processo.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" dark depressed @click="passwordDialog = false">
            Não
          </v-btn>
          <v-btn color="primary" dark depressed @click="resetSenha()">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="390">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-if="deleteUser"
          v-on="on"
          class="px-4 ml-2"
          color="accent"
          dark
          depressed
          :disabled="sending"
        >
          Excluir
        </v-btn>
      </template>

      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon color="red" x-large class="mr-3">
            mdi-alert-circle-outline
          </v-icon>
          Exclusão de Usuário
          <v-btn
            icon
            style="position: absolute; right: 5px; top: 5px"
            @click="deleteDialog = false"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-2">
          <p>Deseja realmente excluir o usuário {{ userName }}?</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" dark depressed @click="deleteDialog = false">
            Não
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            @click="doDelete()"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn class="px-4 ml-2" color="primary" dark depressed :disabled="sending" @click="$emit('save', $event)">
      Salvar
    </v-btn>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    MenuPreview: () => import('./menu-preview.vue'),
  },
  computed: {    
    ...mapGetters(['user']),
    hasPermissions() {
      return Array.isArray(this.permissions);
    },
    resource: function () {
      return this.apiResource(`/v1/acesso/controleacesso`);
    },
  },
  data: function () {
    return {
      deleteDialog: false,
      passwordDialog: false,
    };
  },
  methods: {
    doBack: function () {
      this.$router.push({ name: "listagem-usuarios" });
    },
    doDelete: async function () {
      if (this.sending) {
        return;
      }

      if (isNaN(this.userId)) {
        throw new Error(`Não é possível apagar usuário pois o id fornecido não é válido. id: ${this.userId}`);
      }

      try {
        this.$emit('update:sending', true);
        const response = await this.resource.delete(this.userId);

        if (response.error) {
          throw response;
        }

        this.deleteDialog = false;
        this.doBack();
      } catch (e) {
        this.$emit('error', e.error);
      } finally {
        this.$emit('update:sending', false);
      }
    },
    resetSenha: async function () {
      if (this.sending) {
        return;
      }

      if (isNaN(this.userId)) {
        throw new Error(`Não é possível redefinir a senha do usuário pois o id fornecido não é válido. id: ${this.userId}`);
      }

      try {
        this.$emit('update:sending', true);
        const responseUser = await this.resource.get(this.userId);

        if (responseUser.error) {
          throw responseUser;
        }

        const [user] = responseUser;
        const responsePass = await this.apiResource(`/v1/recuperarSenha?usuario=${user.usuario}&cc=${this.user.email}`).get();
        
        if (responsePass.error) {
          throw responsePass;
        }

        this.passwordDialog = false
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.$emit('update:sending', false);
      }
    },
  },
  props: {
    deleteUser: { type: Boolean, default: false },
    permissions: { type: Array, default: () => null },
    resetPassword: { type: Boolean, default: false },
    sending: { type: Boolean, default: false },
    userEmail: { type: String, default: 'cadastrado' },
    userId: { type: Number, default: null },
    userName: { type: String, default: 'selecionado' },
  },
};
</script>
